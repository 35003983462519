export default {
  getWebSocketUrl: function () {
    if (window.location.host.includes('localhost')) {
      return 'wss://b-3e5d3259-f80a-4759-80d2-629ddd10388f-1.mq.eu-west-1.amazonaws.com:61619';
    } else if (window.location.href.includes('mgmt-ui-dev')) {
      return 'wss://b-3e5d3259-f80a-4759-80d2-629ddd10388f-1.mq.eu-west-1.amazonaws.com:61619';
    } else {
      return 'wss://b-a35d7656-1ed0-4c19-bdfb-d24f57b5f352-1.mq.eu-west-1.amazonaws.com:61619';
    }
  },
  getAPIUrl: function () {
    // It is mixed around
    if (window.location.host.includes('localhost')) {
      return 'http://localhost:8080/';
    } else if (window.location.href.includes('mgmt-ui-dev')) {
      return 'https://dev-apis.opnbuildings.com/webapis/';
    } else {
      return 'https://apis-env.opnbuildings.com/webapis/';
    }
  },
  getReceiverUrl: function () {
    if (window.location.host.includes('localhost')) {
      return 'http://localhost:8080/';
    } else if (window.location.href.includes('mgmt-ui-dev')) {
      return 'https://dev-receiver.opnbuildings.com/receiver/';
    } else {
      return 'https://receiver.opnbuildings.com/receiver/';
    }
  },
  getEngineUrl: function () {
    if (window.location.host.includes('localhost')) {
      return 'http://localhost:8080/';
    } else if (window.location.href.includes('mgmt-ui-dev')) {
      return 'https://dev-engines.opnbuildings.com/engines/';
    } else {
      return 'https://engines.opnbuildings.com/engines/';
    }
  },
  getUIUrl: function () {
    if (window.location.host.includes('localhost')) {
      return 'http://localhost:8000/';
    } else if (window.location.href.includes('mgmt-ui-dev')) {
      return 'https://dev-ui.opnbuildings.com/';
    } else {
      return 'https://ui.opnbuildings.com/';
    }
  },
  addQuery: function (searchString, query) {
    if (!searchString.includes(query)) {
      searchString = searchString + '&' + query;
    } else {
      searchString = searchString.replace('&' + query, '');
    }
    return searchString;
  }
};
