import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import SuperRoutes from './SuperRoutes';
import TenantRoutes from './TenantRoutes';
import PublicRoutes from './PublicRoutes';
import useAuth from 'hooks/useAuth';
import Constants from '../utils/Constants';
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { userData } = useAuth();

  let common_routes = [LoginRoutes, PublicRoutes];
  let routes = [];
  if (userData?.role === Constants.user_roles.super) routes = [...common_routes, SuperRoutes];
  if (userData?.role === Constants.user_roles.admin) routes = [...common_routes, SuperRoutes];
  if (userData?.role === Constants.user_roles.landlord) routes = [...common_routes, SuperRoutes];
  if (userData?.role === Constants.user_roles.tenant) routes = [...common_routes, TenantRoutes];

  // fallback
  if (routes.length === 0) {
    routes = common_routes;
  }

  return useRoutes(routes);
}
