import { createSlice } from '@reduxjs/toolkit';

// project import
import axios from 'utils/axios';
import { dispatch } from 'store';
import UrlManager from '_api/UrlManager';

const initialState = {
  summary: null,
  buildings: [],
  isLoading: false,
  detailedIntensity: null,
  error: '',
  period: 'week'
};

// ==============================|| ORGANISATION OVERVIEW - SLICE ||============================== //

const organisation = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    // loader
    setLoading(state) {
      state.isLoading = true;
    },

    // set organisation's data
    setOrganisationData(state, action) {
      state.summary = action.payload.summary;
      state.buildings = action.payload.buildings;
      state.isLoading = false;
    },

    setOrganisationDetailedIntensity(state, action) {
      state.detailedIntensity = action.payload;
      state.error = '';
      state.isLoading = false;
    },

    setPeriod(state, action) {
      state.period = action.payload;
    },

    setError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { setPeriod } = organisation.actions;

export default organisation.reducer;

export function getOrganisation(orgId, period) {
  return async () => {
    dispatch(organisation.actions.setLoading());
    try {
      const response = await axios.get(UrlManager.getAPIUrl() + `performance-rating/organisation/${orgId}?period=${period}`);
      dispatch(organisation.actions.setOrganisationData(response.data));
    } catch (error) {
      dispatch(organisation.actions.setError(error));
    }
  };
}

export function getOrganisationDetailedIntensity(orgId, period, bucket) {
  return async () => {
    dispatch(organisation.actions.setLoading());
    try {
      const response = await axios.get(
        UrlManager.getAPIUrl() + `performance-rating/organisation/${orgId}/chart?period=${period}&bucket=${bucket}`
      );
      dispatch(organisation.actions.setOrganisationDetailedIntensity(response.data));
    } catch (error) {
      dispatch(organisation.actions.setError(error));
    }
  };
}
