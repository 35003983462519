import { createSlice } from '@reduxjs/toolkit';

// project import
import axios from 'utils/axios';
import { dispatch } from 'store';
import UrlManager from '_api/UrlManager';

const initialState = {
  emissionsScope: {
    scope_one: null,
    scope_two: null,
    scope_three: null,
    prevented: null,
    total: null,
    series: {
      scope_one: [],
      scope_two: [],
      scope_three: [],
      prevented: []
    }
  },
  isLoading: false,
  period: 'week'
};

// ==============================|| EMISSIONS BREAKDOWN OVERVIEW - SLICE ||============================== //

const emissionsScope = createSlice({
  name: 'emissionsScope',
  initialState,
  reducers: {
    // loader
    setLoading(state) {
      state.isLoading = true;
    },

    // set organisation's data
    setEmissionsScope(state, action) {
      state.emissionsScope = action.payload;
      state.isLoading = false;
    },

    setPeriod(state, action) {
      state.period = action.payload;
    },

    setError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { setPeriod } = emissionsScope.actions;

export default emissionsScope.reducer;

export function getEmissionsScope(orgId, period) {
  return async () => {
    dispatch(emissionsScope.actions.setLoading());
    try {
      const response = await axios.get(UrlManager.getAPIUrl() + `emissions-breakdown/organisation/${orgId}?period=${period}`);
      dispatch(emissionsScope.actions.setEmissionsScope(response.data));
    } catch (error) {
      dispatch(emissionsScope.actions.setEmissionsScope(initialState.emissionsScope));
      dispatch(emissionsScope.actions.setError(error));
    }
  };
}
export function getBuildingEmissionsScope(buildingId, period) {
  return async () => {
    dispatch(emissionsScope.actions.setLoading());
    try {
      const response = await axios.get(UrlManager.getAPIUrl() + `emissions-breakdown/building/${buildingId}?period=${period}`);
      dispatch(emissionsScope.actions.setEmissionsScope(response.data));
    } catch (error) {
      dispatch(emissionsScope.actions.setEmissionsScope(initialState.emissionsScope));
      dispatch(emissionsScope.actions.setError(error));
    }
  };
}
