import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';

import OPNLogo from 'assets/images/OPNLogoSlogan-white.png';
import OPNLogoDark from 'assets/images/OPNLogoSlogan-white.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoLogin = () => {
  const theme = useTheme();
  return (
    <>
      <img src={theme.palette.mode === 'dark' ? OPNLogoDark : OPNLogo} alt="OPNBuildings" width="100%" style={{ maxWidth: '410px' }} />
    </>
  );
};

LogoLogin.propTypes = {
  reverse: PropTypes.bool
};

export default LogoLogin;
