// material-ui
import { Box } from '@mui/material';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  return (
    <>
      <Box sx={{ width: '100%', ml: 1 }} />
    </>
  );
};

export default HeaderContent;
