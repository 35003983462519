import { createSlice } from '@reduxjs/toolkit';

// project import
import axios from 'utils/axios';
import { dispatch } from 'store';
import UrlManager from '_api/UrlManager';

const initialState = {
  energyBreakdown: {
    net: {
      total: null,
      electrical: null,
      thermal: null,
      natural_gas: null
    },
    generated: {
      total: null,
      electricity_pv: null,
      electricity_wind: null,
      electricity_chp: null,
      thermal_solar: null,
      geo_heat_pump: null,
      air_heat_pump: null,
      thermal_chp: null
    },
    imported: {
      total: null,
      electricity: null,
      district_heat: null,
      district_cooling: null,
      natural_gas: null
    },
    exported: {
      total: null,
      electricity: null,
      heat: null,
      cooling: null
    }
  },
  emissionsBreakdown: {
    net: {
      total: null,
      electrical: null,
      thermal: null,
      natural_gas: null
    },
    generated: {
      total: null,
      electricity_pv: null,
      electricity_wind: null,
      electricity_chp: null,
      thermal_solar: null,
      geo_heat_pump: null,
      air_heat_pump: null,
      thermal_chp: null
    },
    imported: {
      total: null,
      electricity: null,
      district_heat: null,
      district_cooling: null,
      natural_gas: null
    },
    exported: {
      total: null,
      electricity: null,
      heat: null,
      cooling: null
    }
  },
  isLoading: false,
  period: 'week'
};

// ==============================|| ENERGY BREAKDOWN OVERVIEW - SLICE ||============================== //

const energyAndEmissionsBreakdown = createSlice({
  name: 'energyAndEmissionsBreakdown',
  initialState,
  reducers: {
    // loader
    setLoading(state) {
      state.isLoading = true;
    },

    // set organisation's data
    setEnergyAndEmissionsBreakdown(state, action) {
      state.energyBreakdown = action.payload.energy;
      state.emissionsBreakdown = action.payload.emissions;
      state.isLoading = false;
    },

    setPeriod(state, action) {
      state.period = action.payload;
    },

    setError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { setPeriod } = energyAndEmissionsBreakdown.actions;

export default energyAndEmissionsBreakdown.reducer;

export function getEnergyAndEmissionsBreakdown(orgId, period) {
  return async () => {
    dispatch(energyAndEmissionsBreakdown.actions.setLoading());
    try {
      const response = await axios.get(UrlManager.getAPIUrl() + `energy-balance/organisation/${orgId}?period=${period}`);
      dispatch(energyAndEmissionsBreakdown.actions.setEnergyAndEmissionsBreakdown(response.data));
    } catch (error) {
      dispatch(energyAndEmissionsBreakdown.actions.setEnergyAndEmissionsBreakdown(initialState));
      dispatch(energyAndEmissionsBreakdown.actions.setError(error));
    }
  };
}

export function getBuildingEnergyAndEmissionsBreakdown(buildingId, period) {
  return async () => {
    dispatch(energyAndEmissionsBreakdown.actions.setLoading());
    try {
      const response = await axios.get(UrlManager.getAPIUrl() + `energy-balance/building/${buildingId}?period=${period}`);
      dispatch(energyAndEmissionsBreakdown.actions.setEnergyAndEmissionsBreakdown(response.data));
    } catch (error) {
      console.log(initialState);
      dispatch(energyAndEmissionsBreakdown.actions.setEnergyAndEmissionsBreakdown(initialState));
      dispatch(energyAndEmissionsBreakdown.actions.setError(error));
    }
  };
}
