import { createSlice } from '@reduxjs/toolkit';

// project import
import axios from 'utils/axios';
import { dispatch } from 'store';
import UrlManager from '_api/UrlManager';

const initialState = {
  summary: null,
  buildings: [],
  tenants: [],
  isLoading: false,
  detailedIntensity: null,
  error: '',
  period: 'week',
  // assessment
  assessmentDetails: null,
  opportunities: [],
  grade: null,
  assessmentDate: null,
  assessmentId: null
};

// ==============================|| building OVERVIEW - SLICE ||============================== //

const building = createSlice({
  name: 'building',
  initialState,
  reducers: {
    // loader
    setLoading(state) {
      state.isLoading = true;
    },

    // set building's data
    setBuildingData(state, action) {
      state.summary = action.payload.summary;
      state.buildings = action.payload.buildings;
      state.tenants = action.payload.buildings.length > 0 ? action.payload.buildings[0].tenants : [];
      state.isLoading = false;
    },

    setAssessmentInfo(state, action) {
      let assessmentDetails = action.payload;
      state.assessmentDetails = assessmentDetails;

      let opportunities = [];

      assessmentDetails.category_checks.forEach((cat) => {
        cat.checks.forEach((check) => {
          if (check.asset_checks.length === 0) {
            opportunities.push({
              ...check,
              category: cat.category,
              asset_name: null,
              check: check.description,
              timestamp: state.assessmentDate
            });
          } else {
            check.asset_checks.forEach((asset) => {
              opportunities.push({
                ...asset,
                name: asset.asset_name + ' - ' + check.name,
                check: check.name,
                comment: check.comment,
                description: check.description,

                category: cat.category,
                requirements: check.requirements,
                timestamp: state.assessmentDate
              });
            });
          }
        });
      });

      state.opportunities = opportunities;
      state.isLoading = false;
    },

    setBuildingDetailedIntensity(state, action) {
      state.detailedIntensity = action.payload;
      state.error = '';
      state.isLoading = false;
    },

    setBuildingGrade(state, action) {
      let gradeLabel = null;
      let assessmentDate = null;
      let assessmentId = null;

      if (action.payload[0]) {
        const grade = action.payload[0].overall_grade_value;
        assessmentDate = action.payload[0].ts_create;
        assessmentId = action.payload[0].assessment_id;
        gradeLabel = 'D';
        if (grade > 1.17 && grade <= 1.5) {
          gradeLabel = 'D-C';
        } else if (grade > 1.5 && grade <= 1.83) {
          gradeLabel = 'C-D';
        } else if (grade > 1.83 && grade <= 2.17) {
          gradeLabel = 'C';
        } else if (grade > 2.17 && grade <= 2.5) {
          gradeLabel = 'C-B';
        } else if (grade > 2.5 && grade <= 2.83) {
          gradeLabel = 'B-C';
        } else if (grade > 2.83 && grade <= 3.17) {
          gradeLabel = 'B';
        } else if (grade > 3.17 && grade <= 3.5) {
          gradeLabel = 'B-A';
        } else if (grade > 3.5 && grade <= 3.83) {
          gradeLabel = 'A-B';
        } else if (grade > 3.83 && grade <= 4.1) {
          gradeLabel = 'A';
        }
      }

      state.grade = gradeLabel;
      state.assessmentId = assessmentId;
      state.assessmentDate = assessmentDate;
      state.isLoading = false;
    },

    setPeriod(state, action) {
      state.period = action.payload;
    },

    setError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { setPeriod } = building.actions;

export default building.reducer;

export function getBuilding(buildingId, period) {
  return async () => {
    dispatch(building.actions.setLoading());
    try {
      const response = await axios.get(UrlManager.getAPIUrl() + `performance-rating/building/${buildingId}?period=${period}`);
      dispatch(building.actions.setBuildingData(response.data));
    } catch (error) {
      dispatch(building.actions.setError(error));
    }
  };
}

export function getBuildingGrade(buildingId) {
  return async () => {
    dispatch(building.actions.setLoading());
    try {
      const response = await axios.get(UrlManager.getAPIUrl() + `assessment/building/${buildingId}`);
      dispatch(building.actions.setBuildingGrade(response.data));
    } catch (error) {
      dispatch(building.actions.setError(error));
    }
  };
}

export function getAssessment(id) {
  return async () => {
    dispatch(building.actions.setLoading());
    try {
      const response = await axios.get(UrlManager.getAPIUrl() + `assessment/${id}`);
      dispatch(building.actions.setAssessmentInfo(response.data));
    } catch (error) {
      dispatch(building.actions.setError(error));
    }
  };
}

export function getBuildingDetailedIntensity(buildingId, period, bucket) {
  return async () => {
    dispatch(building.actions.setLoading());
    try {
      const response = await axios.get(
        UrlManager.getAPIUrl() + `performance-rating/building/${buildingId}/chart?period=${period}&bucket=${bucket}`
      );
      dispatch(building.actions.setBuildingDetailedIntensity(response.data));
    } catch (error) {
      dispatch(building.actions.setError(error));
    }
  };
}
