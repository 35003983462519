import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';

import OPNLogo from 'assets/images/OPNLogo-blue.svg';
import OPNLogoDark from 'assets/images/OPNLogo-white.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = () => {
  const theme = useTheme();
  return (
    <>
      <img src={theme.palette.mode === 'dark' ? OPNLogoDark : OPNLogo} alt="OPNBuildings" width="210" />
    </>
  );
};

LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;
