import { useState, useMemo, useEffect } from 'react';
import useAuth from 'hooks/useAuth';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';

import { useSelector, dispatch } from 'store';
import { getOrganisation } from 'store/reducers/organisation';
import useConfig from 'hooks/useConfig';
import { HORIZONTAL_MAX_ITEM, LAYOUT_CONST } from 'config';
import Constants from 'utils/Constants';

// project import
import NavGroup from './NavGroup';
import navigationItems from 'menu-items';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const theme = useTheme();
  const { user, userData } = useAuth();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { buildings } = useSelector((state) => state.organisation);
  const [selectedItems, setSelectedItems] = useState('');
  const [selectedLevel, setSelectedLevel] = useState(0);

  useEffect(() => {
    if (user && buildings.length === 0) dispatch(getOrganisation(user?.fk_organisation_id, 'week'));
  }, [buildings, user]);

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  let menuItem = {
    items: []
  };
  if (userData?.role === Constants.user_roles.super) {
    menuItem = navigationItems.super;
  } else if (userData?.role === Constants.user_roles.admin) {
    menuItem = navigationItems.admin;
  } else if (userData?.role === Constants.user_roles.landlord) {
    menuItem = navigationItems.landlord;
  } else if (userData?.role === Constants.user_roles.tenant) {
    menuItem = navigationItems.tenant;
  } else if (userData) {
    // FALLBACK
    menuItem = navigationItems.super;
  }

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItem.items.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < menuItem.items.length) {
    lastItemId = menuItem.items[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    remItems = menuItem.items.slice(lastItem - 1, menuItem.items.length).map((item) => ({
      title: item.title,
      elements: item.children,
      icon: item.icon
    }));
  }

  // Injecting fetched building into Navigation
  let modified_menu_items = useMemo(
    () =>
      menuItem.items.map((item) => {
        if (item.id === 'buildings') {
          return {
            ...item,
            children: item.children.map((i) => {
              if (i.id === 'building-overview') {
                return {
                  ...i,
                  children: buildings.map((b) => {
                    return {
                      id: 'building-overview' + b.building_id,
                      title: b.building_name,
                      type: 'item',
                      url: '/building-overview/' + b.building_id
                    };
                  })
                };
              }

              return i;
            })
          };
        }

        return item;
      }),
    [buildings, menuItem.items]
  );

  const navGroups = modified_menu_items.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            key={item.id}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
            lastItem={lastItem}
            remItems={remItems}
            lastItemId={lastItemId}
            item={item}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        '& > ul:first-of-type': { mt: 0 },
        display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block',
        pb: '60px'
      }}
    >
      {navGroups}
    </Box>
  );
};

export default Navigation;
