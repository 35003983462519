// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import organisation from './organisation';
import building from './building';
import tenant from './tenant';
import energyAndEmissionsBreakdown from './energyAndEmissionsBreakdown';
import emissionsScope from './emissionsScope';
import buildingPerformanceCalculator from './buildingPerformanceCalculator';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  organisation,
  building,
  tenant,
  emissionsScope,
  energyAndEmissionsBreakdown,
  buildingPerformanceCalculator
});

export default reducers;
