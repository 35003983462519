import { createSlice } from '@reduxjs/toolkit';

// project import
import axios from 'utils/axios';
import { dispatch } from 'store';
import UrlManager from '_api/UrlManager';

const initialState = {
  isLoading: false,
  detailedIntensity: null,
  error: '',
  period: 'week'
};

// ==============================|| tenant OVERVIEW - SLICE ||============================== //

const tenant = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    // loader
    setLoading(state) {
      state.isLoading = true;
    },

    setTenantDetailedIntensity(state, action) {
      state.detailedIntensity = action.payload;
      state.error = '';
      state.isLoading = false;
    },

    setPeriod(state, action) {
      state.period = action.payload;
    },

    setError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { setPeriod } = tenant.actions;

export default tenant.reducer;

export function getTenantDetailedIntensity(buildingId, tenantId, period, bucket) {
  return async () => {
    dispatch(tenant.actions.setLoading());
    try {
      const response = await axios.get(
        UrlManager.getAPIUrl() + `performance-rating/building/${buildingId}/tenant/${tenantId}/chart?period=${period}&bucket=${bucket}`
      );
      dispatch(tenant.actions.setTenantDetailedIntensity(response.data));
    } catch (error) {
      dispatch(tenant.actions.setError(error));
    }
  };
}
