// third-party
import { FormattedMessage } from 'react-intl';

import { HomeOutlined } from '@ant-design/icons';

// icons
const icons = {
  HomeOutlined
};

// ==============================|| MENU ITEMS - BUILDINGS ||============================== //

const building_items = {
  id: 'buildings',
  title: <FormattedMessage id="buildings" />,
  type: 'group',
  children: [
    {
      id: 'building-overview',
      title: <FormattedMessage id="building-overview" />,
      type: 'collapse',
      breadcrumbs: true,
      icon: icons.HomeOutlined
    }
  ]
};

export default building_items;
