import { lazy } from 'react';

// project import
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';

const BuildingPerformanceCalculator = Loadable(
  lazy(() => import('pages/public/building-performance-calculator/building-performance-calculator'))
);

const PublicRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <CommonLayout />,
      children: [
        {
          path: 'building-performance-calculator/:token',
          element: <BuildingPerformanceCalculator />
        }
      ]
    }
  ]
};

export default PublicRoutes;
