// ==============================|| OVERRIDES - BUTTON GROUP ||============================== //

export default function ButtonGroup() {
  return {
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        grouped: {
          fontWeight: 500
        }
      }
    }
  };
}
