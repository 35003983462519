import { useEffect, useState, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'hooks/useAuth';
import PropTypes from 'prop-types';
import Constants from 'utils/Constants';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
import HorizontalBar from './Drawer/HorizontalBar';
// import Breadcrumbs from 'components/@extended/Breadcrumbs';

import navigationItems from 'menu-items';
import useConfig from 'hooks/useConfig';
import { openDrawer } from 'store/reducers/menu';
import { LAYOUT_CONST } from 'config';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { userData } = useAuth();

  const { container, miniDrawer, menuOrientation } = useConfig();
  const { buildings } = useSelector((state) => state.organisation);
  const dispatch = useDispatch();

  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);

  let navigation = {
    items: []
  };
  if (userData?.role === Constants.user_roles.super) {
    navigation = navigationItems.super;
  } else if (userData?.role === Constants.user_roles.admin) {
    navigation = navigationItems.admin;
  } else if (userData?.role === Constants.user_roles.landlord) {
    navigation = navigationItems.landlord;
  } else if (userData?.role === Constants.user_roles.tenant) {
    navigation = navigationItems.tenant;
  } else if (userData) {
    // fallback
    navigation = navigationItems.super;
  }

  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      const isTenant = userData?.role === 'tenant';
      if (isTenant) {
        setOpen(false); // false > true
        dispatch(openDrawer({ drawerOpen: false }));
      } else {
        setOpen(!matchDownLG); // false > true
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  let modified_menu_items = useMemo(
    () =>
      navigation.items.map((item) => {
        if (item.id === 'buildings') {
          return {
            ...item,
            children: item.children.map((i) => {
              if (i.id === 'building-overview') {
                return {
                  ...i,
                  children: buildings.map((b) => {
                    return {
                      id: 'building-overview' + b.building_id,
                      title: b.building_name,
                      type: 'item',
                      url: '/building-overview/' + b.building_id
                    };
                  })
                };
              }

              return i;
            })
          };
        }

        return item;
      }),
    [buildings, navigation.items]
  );

  let modified_navigation = {
    items: modified_menu_items
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', background: theme.palette.background }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      {!isHorizontal ? <Drawer open={open} handleDrawerToggle={handleDrawerToggle} /> : <HorizontalBar />}
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit' }} />
        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            ...(container && { px: { xs: 0, sm: 2 } }),
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {/* <Breadcrumbs navigation={modified_navigation} title={title} titleBottom card={false} divider={false} /> */}
          <Outlet navigation={modified_navigation} />
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;

MainLayout.propTypes = {
  title: PropTypes.bool
};
