import PropTypes from 'prop-types';
import { useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// project import
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { DRAWER_WIDTH } from 'config';
import Profile from '../../MainLayout/Header/HeaderContent/Profile';
import { useSelector } from 'store';

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = ({ open, handleDrawerToggle, window }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  // responsive drawer container
  const container = window !== undefined ? () => window().document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);
  const { drawerOpen } = useSelector((state) => state.menu);

  const getProfile = () => {
    if (drawerOpen) {
      return (
        <Box sx={{ background: 'white', position: 'fixed', bottom: 0, left: 0, py: 2, right: `calc(100vw - ${DRAWER_WIDTH}px)` }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Profile />
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ background: 'white', position: 'fixed', bottom: 0, left: 0, py: 2, right: `calc(100vw - ${DRAWER_WIDTH - 200}px)` }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Profile justAvatar />
          </Box>
        </Box>
      );
    }
  };

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1200 }} aria-label="mailbox folders">
      {!matchDownMD ? (
        <MiniDrawerStyled variant="permanent" open={open}>
          {drawerHeader}
          {drawerContent}
          {getProfile()}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              borderRight: `1px solid ${theme.palette.divider}`,
              backgroundImage: 'none',
              boxShadow: 'inherit'
            }
          }}
        >
          {drawerHeader}
          {drawerContent}
          <Box sx={{ background: 'white', position: 'fixed', bottom: 0, left: 0, py: 2, right: `calc(100vw - ${DRAWER_WIDTH}px)` }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Profile />
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

MainDrawer.propTypes = {
  open: PropTypes.bool,
  window: PropTypes.object,
  handleDrawerToggle: PropTypes.func
};

export default MainDrawer;
