// material-ui
import { alpha, createTheme } from '@mui/material/styles';

// third-party
import { presetDarkPalettes, presetPalettes } from '@ant-design/colors';

// project import
import ThemeOption from './theme';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode, presetColor) => {
  const colors = mode === 'dark' ? presetDarkPalettes : presetPalettes;

  let greyPrimary = [
    '#ffffff',
    '#fafafa',
    '#f5f5f5',
    '#f0f0f0',
    '#d9d9d9',
    '#bfbfbf',
    '#8c8c8c',
    '#595959',
    '#262626',
    '#141414',
    '#000000'
  ];
  let greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
  let greyConstant = ['#fafafb', '#e6ebf1'];

  if (mode === 'dark') {
    greyPrimary = ['#000000', '#141414', '#1e1e1e', '#595959', '#8c8c8c', '#bfbfbf', '#d9d9d9', '#f0f0f0', '#f5f5f5', '#fafafa', '#ffffff'];
    // greyPrimary.reverse();
    greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
    greyConstant = ['#121212', '#d3d8db'];
  }
  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

  const paletteColor = ThemeOption(colors, presetColor, mode);

  return createTheme({
    palette: {
      ...paletteColor,
      mode,
      common: {
        black: '#000',
        white: '#fff'
      },
      text: {
        primary: mode === 'dark' ? alpha(paletteColor.grey[900], 0.87) : paletteColor.grey[700],
        secondary: mode === 'dark' ? alpha(paletteColor.grey[900], 0.45) : '#555555',
        // secondary: mode === 'dark' ? alpha(paletteColor.grey[900], 0.45) : paletteColor.grey[500],
        disabled: mode === 'dark' ? alpha(paletteColor.grey[900], 0.1) : paletteColor.grey[400]
      },
      action: {
        disabled: paletteColor.grey[300]
      },

      purple: {
        main: '#5244AB',
        100: '#E2DFF2',
        200: '#C6C0E5',
        300: '#AAA1D8',
        400: '#8D81CB',
        500: '#7162BE',
        600: '#5543B2',
        700: '#463794',
        800: '#382C76',
        900: '#2A2159',
        1000: '#1C163B',
        1100: '#0E0B1D'
      },
      blue: {
        main: '#0065C4',
        100: '#D4E5F6',
        200: '#AACCED',
        300: '#7FB3E5',
        400: '#5599DC',
        500: '#2A80D3',
        600: '#0067CB',
        700: '#0055A9',
        800: '#004487',
        900: '#003365',
        1000: '#002243',
        1100: '#001121'
      },
      lightblue: {
        main: '#00B8D9',
        100: '#D4F3F9',
        200: '#AAE8F3',
        300: '#7FDDEE',
        400: '#55D1E8',
        500: '#2AC6E2',
        600: '#00BBDD',
        700: '#009BB8',
        800: '#007C93',
        900: '#005D6E',
        1000: '#003E49',
        1100: '#001F24'
      },
      green: {
        main: '#00B195',
        100: '#D4F2ED',
        200: '#AAE6DB',
        300: '#7FD9C9',
        400: '#55CDB7',
        500: '#2AC0A5',
        600: '#00B494',
        700: '#00967B',
        800: '#007862',
        900: '#005A4A',
        1000: '#003C31',
        1100: '#001E18'
      },
      yellow: {
        main: '#FEDA3B',
        100: '#FFF8D4',
        200: '#FFF2AA',
        300: '#FFEB7F',
        400: '#FFE555',
        500: '#FFDE2A',
        600: '#FFD800',
        700: '#D4B400',
        800: '#AA9000',
        900: '#7F6C00',
        1000: '#554800',
        1100: '#2A2400'
      },
      orange: {
        main: '#FEAD39',
        100: '#FFF0D4',
        200: '#FFE2AA',
        300: '#FFD37F',
        400: '#FFC555',
        500: '#FFB62A',
        600: '#FFA800',
        700: '#D48C00',
        800: '#AA7000',
        900: '#7F5400',
        1000: '#553800',
        1100: '#2A1C00'
      },
      red: {
        main: '#F55768',
        100: '#FFE0E5',
        200: '#FFC1CB',
        300: '#FFA2B1',
        400: '#FF8397',
        500: '#FF647D',
        600: '#FF4664',
        700: '#D43A53',
        800: '#AA2E42',
        900: '#7F2332',
        1000: '#551721',
        1100: '#2A0B10'
      },
      black: {
        main: '#222222',
        dark: '#000',
        contrastText: '#fff'
      },
      fontBlack: {
        darker: '#262626FF',
        dark: '#262626E6',
        main: '#262626CC',
        light: '#262626b3',
        lighter: '#26262699'
      },
      divider: mode === 'dark' ? alpha(paletteColor.grey[900], 0.05) : paletteColor.grey[200],
      background: {
        paper: mode === 'dark' ? paletteColor.grey[100] : paletteColor.grey[0],
        default: '#f8f8f8'
        // default: paletteColor.grey.A50
      }
    }
  });
};

export default Palette;
