// project import
import organisation_items from './organisation_items';
import building_items from './building_items';
// import tenant_items from './tenant_items';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  super: {
    items: [organisation_items, building_items]
  },
  admin: {
    items: [organisation_items, building_items]
  },
  landlord: {
    items: [organisation_items, building_items]
  }
};
// const menuItems = {
//   super: {
//     items: [organisation_items, building_items, tenant_items.admin]
//   },
//   admin: {
//     items: [organisation_items, building_items, tenant_items.admin]
//   },
//   landlord: {
//     items: [organisation_items, building_items, tenant_items.admin]
//   },
//   tenant: {
//     items: [tenant_items.tenant]
//   }
// };

export default menuItems;
