import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'components/logo';
import useConfig from 'hooks/useConfig';
import { LAYOUT_CONST } from 'config';

import IconButton from 'components/@extended/IconButton';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { useDispatch } from 'react-redux';
import { openDrawer } from 'store/reducers/menu';
import { DRAWER_WIDTH } from 'config';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const { menuOrientation } = useConfig();
  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT;

  const handleDrawerToggle = () => {
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  const getIconPosition = () => {
    if (open && downLG) return DRAWER_WIDTH - 18;
    if (open) return DRAWER_WIDTH - 2;
    return 85;
  };

  return (
    <DrawerHeaderStyled
      theme={theme}
      open={open}
      sx={{
        minHeight: isHorizontal ? 'unset' : '60px',
        width: isHorizontal ? { xs: '100%', lg: '424px' } : 'inherit',
        paddingTop: isHorizontal ? { xs: '10px', lg: '0' } : '8px',
        paddingBottom: isHorizontal ? { xs: '18px', lg: '0' } : '8px',
        paddingLeft: isHorizontal ? { xs: '24px', lg: '0' } : open ? '24px' : 0
      }}
    >
      <Logo isIcon={!open} sx={{ width: open ? 'auto' : 35, height: 35 }} />
      <>
        {!isHorizontal ? (
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            color="secondary"
            variant="light"
            shape="rounded"
            sx={{
              color: 'text.primary',
              bgcolor: 'white',
              ml: { xs: 0, lg: -2 },
              border: '1px solid #ebebeb',
              position: 'fixed',
              transition: 'left 0.2s linear',
              left: getIconPosition()
            }}
          >
            {!open ? <RightOutlined /> : <LeftOutlined />}
          </IconButton>
        ) : null}
      </>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
