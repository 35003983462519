import { createSlice } from '@reduxjs/toolkit';

// project import
import axios from 'utils/axios';
import { dispatch } from 'store';
import UrlManager from '_api/UrlManager';

const initialState = {
  data: null,
  error: '',
  isLoading: false
};

// ==============================|| buildingPerformanceCalculator OVERVIEW - SLICE ||============================== //

const buildingPerformanceCalculator = createSlice({
  name: 'buildingPerformanceCalculator',
  initialState,
  reducers: {
    // loader

    // set building's data
    setBuildingPerformanceCalculatorData(state, action) {
      state.data = action.payload;
      state.isLoading = false;
    },

    setLoading(state) {
      state.isLoading = true;
    },

    setError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export default buildingPerformanceCalculator.reducer;

export function getPerformanceData(token) {
  return async () => {
    dispatch(buildingPerformanceCalculator.actions.setLoading());
    try {
      const response = await axios.get(UrlManager.getAPIUrl() + `performance-calculator/${token}`);
      dispatch(buildingPerformanceCalculator.actions.setBuildingPerformanceCalculatorData(response.data));
    } catch (error) {
      dispatch(buildingPerformanceCalculator.actions.setError(error));
    }
  };
}
