// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { TableOutlined, LineChartOutlined, BulbOutlined, CloudUploadOutlined, HomeOutlined } from '@ant-design/icons';

// icons
const icons = {
  TableOutlined,
  LineChartOutlined,
  BulbOutlined,
  CloudUploadOutlined,
  HomeOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const organisation_items = {
  id: 'dashboard',
  title: <FormattedMessage id="dashboard" />,
  type: 'group',
  children: [
    {
      id: 'organisation-overview',
      title: <FormattedMessage id="organisation-overview" />,
      type: 'item',
      url: '/organisation-overview',
      breadcrumbs: true,
      icon: icons.LineChartOutlined
    },
    {
      id: 'energy-and-emissions',
      title: <FormattedMessage id="energy-and-emissions" />,
      type: 'item',
      url: '/energy-and-emissions',
      breadcrumbs: true,
      icon: icons.BulbOutlined
    },
    {
      id: 'emissions-scope',
      title: <FormattedMessage id="emissions-scope" />,
      type: 'item',
      url: '/emissions-scope',
      breadcrumbs: true,
      icon: icons.CloudUploadOutlined
    }
  ]
};

export default organisation_items;
