import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import UrlManager from '../_api/UrlManager';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import axios from 'utils/axios';

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  userData: null
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);

  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);

          const user = JSON.parse(jwtDecode(serviceToken).user);
          const userData = jwtDecode(serviceToken);

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: user,
              userData: userData
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (email, password) => {
    const loginParams = 'app=MANAGEMENT_UI' + '&username=' + email + '&password=' + password;
    const response = await axios.post(UrlManager.getAPIUrl() + 'login', loginParams);
    const serviceToken = response.data.split('Bearer ')[1];
    const user = JSON.parse(jwtDecode(serviceToken).user);
    const userData = jwtDecode(serviceToken);

    setSession(serviceToken);

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
        userData
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const loginWithReset = async (user_id, token, password) => {
    try {
      const resetParams = {
        userId: user_id,
        resetCode: token,
        newPassword: password
      };
      const response = await axios.post(UrlManager.getAPIUrl() + 'tokenlogin', resetParams, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      });
      const serviceToken = response.data.split('Bearer ')[1];
      const user = JSON.parse(jwtDecode(serviceToken).user);
      const userData = jwtDecode(serviceToken);

      setSession(serviceToken);

      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user,
          userData
        }
      });
    } catch (err) {
      throw new Error('Request Failed, please try requesting the reset email link again.');
    }
  };

  const loginWithInvite = async (user_id, token, password) => {
    const loginParams = 'userId=' + user_id + '&inviteToken=' + token + '&newPassword=' + password;
    const response = await axios.post(UrlManager.getAPIUrl() + 'verifyinvite', loginParams);
    const serviceToken = response.data.split('Bearer ')[1];
    const user = JSON.parse(jwtDecode(serviceToken).user);
    const userData = jwtDecode(serviceToken);

    setSession(serviceToken);

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
        userData
      }
    });
  };

  const resetPassword = async (email) => {
    const resetParams = {
      email: email,
      app: 'MANAGEMENT_UI'
    };

    axios.post(UrlManager.getAPIUrl() + 'user/resetpassword', resetParams, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
  };

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        loginWithInvite,
        loginWithReset,
        logout,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
