import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render - sample page
const TenantOverview = Loadable(lazy(() => import('pages/tenant-overview/tenant-overview')));

// ==============================|| MAIN ROUTING ||============================== //

const TenantRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'home',
          element: <Redirect to="/tenant-overview/" />
        },
        {
          path: 'tenant-overview/',
          element: <TenantOverview />
        },
        {
          path: 'tenant-overview/:tenantId',
          element: <TenantOverview />
        },
        {
          path: '*',
          element: <Redirect to="/maintenance/404" />
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

function Redirect({ to }) {
  return <Navigate to={to} replace />;
}

Redirect.propTypes = {
  to: PropTypes.string
};

export default TenantRoutes;
