import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render - sample page
const OrganisationOverview = Loadable(lazy(() => import('pages/organisation-overview/organisation-overview')));
const BuildingOverview = Loadable(lazy(() => import('pages/building-overview/building-overview')));
const TenantOverview = Loadable(lazy(() => import('pages/tenant-overview/tenant-overview')));
const EnergyAndEmissions = Loadable(lazy(() => import('pages/energy-and-emissions/energy-and-emissions')));
const EmissionsScope = Loadable(lazy(() => import('pages/emissions-scope/emissions-scope')));

// ==============================|| MAIN ROUTING ||============================== //

const SuperRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'home',
          element: <Redirect to="/organisation-overview" />
        },
        {
          path: 'organisation-overview',
          element: <OrganisationOverview />
        },
        {
          path: 'building-overview/:buildingId',
          element: <BuildingOverview />
        },
        {
          path: 'building-overview/',
          element: <BuildingOverview />
        },
        {
          path: 'tenant-overview/:tenantId',
          element: <TenantOverview />
        },
        {
          path: 'tenant-overview/',
          element: <TenantOverview />
        },
        {
          path: 'energy-and-emissions',
          element: <EnergyAndEmissions />
        },
        {
          path: 'emissions-scope',
          element: <EmissionsScope />
        },
        {
          path: '*',
          element: <Redirect to="/maintenance/404" />
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

function Redirect({ to }) {
  return <Navigate to={to} replace />;
}

Redirect.propTypes = {
  to: PropTypes.string
};

export default SuperRoutes;
